import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {required, email, regex} from 'vee-validate/dist/rules';
// No message specified.
extend('email', Object.assign({}, email, {
    message: field => field + ' is not valid',
}));
extend('regex', regex);
// extend('min', min);
extend('min', (value, args) => {
    let length = args[0] ? args[0] : args.length;
    if (value.length > length) {
        return true;
    }
    return 'The field must be at least ' + length + ' characters';
});

extend('max', (value, args) => {
    let length = args[0] ? args[0] : args.length;
    if (value.length <= length) {
        return true;
    }
    return 'The field must not be greater than ' + length + '  characters';
});

extend('pass', value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])");
    if (value) {
        return strongRegex.test(value) ? true : 'Password must contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character';
    }
    return true;
});
extend('confirm', {
    message: field => field + ' doesn\'t match',
    validate(value, args) {
        if (value && args && args[0]) {
            return value.toString() === args[0].toString()
        }
        return true;
    }
},);

// Override the default message.
extend('required', {
    ...required,
    message: 'This field is required',
});

export default {
    components: {ValidationProvider, ValidationObserver},
}