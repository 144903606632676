<template>
  <form class="segment_form">
    <div class="row">
      <div class="col-md-5 col-12 d-flex align-baseline justify-content-between">
        <text-input v-model="new_segment.name" label="Name" class="flex-grow-1"
                    :error="hasError('name')"
                    :errors="getErrors('name')"
        ></text-input>
        <div class="segment_color ms-2" :style="{'background-color':new_segment.color}"
             style="margin-top: 35px"></div>
      </div>
      <div class="col-2 ms-auto d-flex align-items-center justify-content-end">
        <span class="me-3 segment_icons" @click="saveSegment()"><i class="fa fa-check "></i></span>
        <span class="segment_icons" @click="deleteSegment()"><i class="fa fa-trash "></i></span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-6 col-lg-4 col-xl-3">
        <form-group :error="hasError('start')" :errors="getErrors('start')">
          <template #label><label class="fw-medium">Segment start</label></template>
          <the-mask v-model="start_segment"
                    label="Segment start"
                    placeholder="00:00"
                    class="form-control"
                    :masked="true"
                    mask="##:##"
          ></the-mask>
        </form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <form-group :error="hasError('end')" :errors="getErrors('end')">
          <template #label><label class="fw-medium">Segment end</label></template>
          <the-mask v-model="end_segment"
                    label="Segment end"
                    placeholder="00:00"
                    class="form-control"
                    :masked="true"
                    mask="##:##"
          ></the-mask>
        </form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <form-group :error="hasError('movement')" :errors="getErrors('movement')">
          <template #label><label class="fw-medium">Movement</label></template>
          <select v-model="new_segment.movement" class="form-control" id="movement" @change="updateCadenceRange()"
                  :class="{'is-invalid': hasError('movement')}"
          >
            <option :value="null" disabled>Select Movement</option>
            <option v-for="movement in movements" :key="movement.id" :value="movement.id">{{ movement.name }}</option>
          </select>
        </form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <label class="fw-medium">Cadence Range</label>
        <div class="d-flex">
          <text-input v-model="new_segment.cadence_range.min" placeholder="Min" class="me-2"
                      :error="hasError('cadence_range.min')" inline/>
          <text-input v-model="new_segment.cadence_range.max" placeholder="Max"
                      :error="hasError('cadence_range.max')" inline/>
        </div>
        <div class="invalid-feedback d-block"
             v-if="hasError('cadence_range.max') || hasError('cadence_range.min')">
            <span class="d-block"
                  v-for="(err, key) in getErrors('cadence_range.max').concat(getErrors('cadence_range.min'))"
                  :key="'err-'+key">{{ err }}</span>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <form-group :error="hasError('zone')" :errors="getErrors('zone')">
          <template #label><label for="zone">Zone</label></template>
          <select v-model="new_segment.zone" class="form-control" id="zone" @change="updateZone()"
                  :class="{'is-invalid': hasError('zone')}"
          >
            <option :value="null" disabled>Select Zone</option>
            <option v-for="zone in zones" :key="zone.id" :value="zone.id">{{ zone.id }}</option>
          </select>
        </form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <label class="fw-medium">% Max Heart Rate</label>
        <div class="d-flex">
          <text-input v-model="new_segment.max_heart_rate.min" placeholder="Min" class="me-2" min="-1" max="100"
                      :error="hasError('max_heart_rate.min')" inline type="number"/>

          <text-input v-if="new_segment.max_heart_rate.max === 100" disabled
                      value="Max" :error="hasError('max_heart_rate.max')">
            <template #append>
              <span class="eye-btn" style="cursor: pointer;" @click="new_segment.max_heart_rate.max = 99"><i
                class="far fa-times"></i></span>
            </template>
          </text-input>
          <text-input v-model.number="new_segment.max_heart_rate.max" placeholder="Max" min="-1" max="100"
                      :error="hasError('max_heart_rate.max')" inline v-else type="number"/>
        </div>
        <div class="invalid-feedback d-block"
             v-if="hasError('max_heart_rate.max') || hasError('max_heart_rate.min')">
            <span class="d-block" v-for="(err, key) in getErrors('max_heart_rate.max').concat(getErrors('max_heart_rate.min'))"
                  :key="'err-'+key">{{ err }}</span>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <label class="fw-medium">FTP / PST %</label>
        <div class="d-flex">
          <text-input v-if="new_segment.pst.min === -1" disabled
                      class="me-2" value="Below" :error="hasError('pst.min')">
            <template #append>
              <span class="eye-btn" style="cursor: pointer;" @click="new_segment.pst.min = 0"><i
                class="far fa-times"></i></span>
            </template>
          </text-input>
          <text-input v-model.number="new_segment.pst.min" placeholder="Min" class="me-2" type="number"
                      :error="hasError('pst.min')" inline v-else/>

          <text-input v-if="new_segment.pst.max === -1" disabled
                      value="Max">
            <template #append>
              <span class="eye-btn" style="cursor: pointer;" @click="new_segment.pst.max = 0"><i
                class="far fa-times"></i></span>
            </template>
          </text-input>
          <text-input v-model.number="new_segment.pst.max" placeholder="Max" type="number" min="-1"
                      :error="hasError('pst.max')" inline v-else/>
        </div>
        <div class="invalid-feedback d-block"
             v-if="hasError('pst.max') || hasError('pst.min')">
            <span class="d-block"
                  v-for="(err, key) in getErrors('pst.max').concat(getErrors('pst.min'))"
                  :key="'err-'+key">{{ err }}</span>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3">
        <text-input v-model="new_segment.rpe" label="RPE" :error="hasError('rpe')" :errors="getErrors('rpe')"/>
      </div>
    </div>
  </form>
</template>
<script>
import TextInput from "@/components/form/TextInput";
import hasApiValidation from "@/mixins/hasApiValidation";
import FormGroup from "@/components/form/FormGroup";
import {mask} from 'vue-the-mask'
import {TheMask} from 'vue-the-mask'

export default {
  name: 'AudioSegmentFormV',
  components: {FormGroup, TextInput, TheMask},
  mixins: [hasApiValidation],
  directives: {mask},
  props: {
    id: {
      required: true
    },
    max: {
      type: Number,
      default: 0,
    },
    segment: {
      type: Object,
    },
    movements: {
      required: true,
      type: Array,
    },
    zones: {
      required: true,
      type: Array,
    },
  },
  computed: {
    start_segment: {
      get: function () {
        if (this.new_segment.start) {
          let minutes = Math.floor((this.new_segment.start / 60));
          let seconds = this.new_segment.start - (minutes * 60);

          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          return minutes + ':' + seconds;
        } else {
          return '00:00'
        }
      },
      set: function (val) {
        this.parseValue(val, 'start')
      },
    },
    end_segment: {
      get: function () {
        if (this.new_segment.end) {
          let minutes = Math.floor((this.new_segment.end / 60));
          let seconds = this.new_segment.end - (minutes * 60);

          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          return minutes + ':' + seconds;
        } else {
          return '00:00'
        }

      },
      set: function (val) {
        this.parseValue(val, 'end')
        // this.new_segment.start = val;
      },
    }
  },
  data() {
    return {
      test: '',
      new_segment: {
        start: 0,
        end: 0,
        color: 'rgba(0,0,0,0.2)',
        drag: false,
        resize: false,
        id: 'new_reg',
        name: 'New Segment',
        movement: null,
        cadence_range: {
          min: null,
          max: null
        },
        zone: null,
        max_heart_rate: {
          min: null,
          max: null
        },
        pst: {
          min: null,
          max: null
        },
        rpe: null,
      },
    }
  },
  mounted() {
    if (this.segment) {
      this.new_segment = Object.assign({}, this.segment);
    }
  },
  methods: {
    parseValue(event, time) {
      let a = event.split(':'); // split it at the colons
      let seconds = (+parseInt(a[0])) * 60 + (+parseInt(a[1]));
      if (seconds > this.max) {
        this.new_segment[time] = Math.floor(this.max);
      } else {
        this.new_segment[time] = seconds;
      }
    },
    updateValue(start, time) {
      this.new_segment[time] = start;
    },
    updateCadenceRange() {
      let mov = this.movements.find((item) => item.id === this.new_segment.movement);
      this.new_segment.cadence_range = {
        min: mov.cadence_min,
        max: mov.cadence_max,
      }
    },
    updateZone() {
      let zone = this.zones.find((item) => item.id === this.new_segment.zone);
      this.new_segment.max_heart_rate = {
        min: zone.mhr.min,
        max: zone.mhr.max,
      }
      this.new_segment.pst = {
        min: zone.pst.min,
        max: zone.pst.max,
      }
      this.new_segment.color = zone.color
      this.new_segment.rpe = zone.rpe
    },
    deleteSegment() {
      if (!this.segment) {
        this.new_segment = {
          start: 0,
          end: 0,
          color: 'rgba(0,0,0,0.2)',
          drag: false,
          resize: false,
          id: 'new_reg',
          name: 'New Segment',
          movement: null,
          cadence_range: {
            min: null,
            max: null
          },
          zone: null,
          max_heart_rate: {
            min: null,
            max: null
          },
          pst: {
            min: null,
            max: null
          },
          rpe: null,
        }
      }
      this.$emit('delete', this.new_segment);
    },
    saveSegment() {
      if (this.segment) {
        this.$emit('errors', {errors: {}, segment: this.segment});
      } else {
        this.setErrors({});
      }
      this.$http.put(`/sessions/${this.id}/segments/validate`, this.new_segment).then(() => {
        this.new_segment.errors = null;
        if (!this.segment) {
          this.$emit('saved', this.new_segment);
          this.new_segment = {
            start: 0,
            end: 0,
            color: 'rgba(0,0,0,0.2)',
            drag: false,
            resize: false,
            id: 'new_reg',
            name: 'New Segment',
            movement: null,
            cadence_range: {
              min: null,
              max: null
            },
            zone: null,
            max_heart_rate: {
              min: null,
              max: null
            },
            pst: {
              min: null,
              max: null
            },
            rpe: null,
          };
        } else {
          this.$emit('updated', this.new_segment);
        }

      }).catch(({response}) => {
        this.new_segment.errors = null;
        if (this.segment) {
          this.$emit('errors', {errors: response.data.errors, segment: this.segment});
        } else {
          this.setErrors(response.data.errors);
        }
      })
    },
    hasError(key) {
      return (this.segment && this.segment.errors && !!this.segment.errors[key]) || (this.errors && !!this.errors[key]);
    },
    getErrors(key) {
      return (this.segment && this.segment.errors && this.segment.errors[key]) ?
        (typeof this.segment.errors[key] === 'string') ? [this.segment.errors[key]] : this.segment.errors[key]
        : (this.errors && this.errors[key]) ?
          (typeof this.errors[key] === 'string') ? [this.errors[key]] : this.errors[key]
          : [];
    },
  },
  watch: {
    // 'segment.errors': {
    //   handler: function (val) {
    //     this.errors = val;
    //   }
    // },
    'segment.end': {
      handler: function (val) {
        this.new_segment.end = val;
      }
    },
    'segment.start': {
      handler: function (val) {
        this.new_segment.start = val;
      }
    },
    'new_segment.end': {
      handler: function (val) {
        if (val > this.max) {
          this.new_segment.end = Math.floor(this.max);
        }
      }
    },
    'new_segment.start': {
      handler: function (val) {
        if (val > this.max) {
          this.new_segment.start = Math.floor(this.max);
        }
      }
    },
  }
}
</script>
<style scoped>
.d-flex > .form-group {
  flex-grow: 1;
}
</style>