import hasValidation from "@/mixins/hasValidation";

export default {
    mixins: [hasValidation],
    data() {
        return {
            errors: {},
        }
    },
    methods: {
        setErrors(errors) {
            this.errors = errors;
        },
        hasError(key, frontFieldValidations = []) {
            if (frontFieldValidations.length > 0 ) {
                return true;
            }
            return (this.errors && !!this.errors[key]);
        },
        getError(key) {
            return (this.errors && this.errors[key]) ?
                (typeof this.errors[key] === 'string') ? this.errors[key] : this.errors[key][0]
                : "";
        },
        getErrors(key, frontFieldValidations = []) {
            if (frontFieldValidations.length > 0 ) {
                return frontFieldValidations;
            }
            return (this.errors && this.errors[key]) ?
                (typeof this.errors[key] === 'string') ? [this.errors[key]] : this.errors[key]
                : [];
        },
        checkState(key) {
            return (this.errors && !!this.errors[key]) ? false : null;
        },
        clearError(key) {
            delete this.errors[key]
        },
        validate() {
            return true;
        },
    },
};
