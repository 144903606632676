import Pagination from "@/components/Pagination";

export default {
  components: {Pagination},
  data() {
    return {
      data: [],
      options: {
        page: 1,
        last_page: 1,
        per_page: 15,
        order_by: 'id',
        order: 'asc'
      },
      filters: {},
      url: '/',
      loading: false,
    }
  },
  mounted() {
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$http.get(this.url, {params: {...this.options, filters: this.filters}}).then(({data}) => {
        this.data = data.data;
        this.options.page = data.meta.current_page;
        this.options.last_page = data.meta.last_page;
        this.options.per_page = data.meta.per_page;
      }).finally(() => {
        this.endLoading();
        this.loading = false;
      });
    },
    endLoading(){

    },
    sort() {

    },
    pageChange(val) {
      this.options.page = val;
      this.fetchData();
    },
    perPageChange(val) {
      this.options.per_page = val;
      this.fetchData();
    },
    sortingChange(val) {
      this.options.order_by = val.order_by;
      this.options.order = val.order
      this.fetchData();
    },
    searchChange(val) {
      this.options.s = val;
      this.fetchData();
    },
  },
  computed: {
    currentPage() {
      return this.options.page;
    },
    pageCount() {
      return this.options.last_page;
    }
  }
}