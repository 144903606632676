<template>
  <div v-if="lastPage > 1">
    <div class="d-flex justify-content-center py-3 px-3">
      <button @click.prevent="prev" :disabled="!canPrev()"
              class="btn btn-outline-primary me-2 page_item_link"> &#60;
      </button>
      <button v-for="page_option in getPageOptions()" class="btn mx-2 page_item_link"
               :class="{
                  'btn-primary': page === page_option,
                  'btn-white color-primary shadow': page !== page_option,
               }" :key="page_option" :disabled="loading" @click.prevent="goTo(page_option)">
        {{ page_option }}
      </button>
      <button @click.prevent="next" :disabled="!canNext()" class="btn btn-outline-primary ms-1 page_item_link"> &#62;
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    lastPage: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      page: 1,
    }
  },

  methods: {

    canPrev() {
      return !this.disabled && this.canGoTo(this.currentPage - 1);
    },

    canNext() {
      return !this.disabled && this.canGoTo(this.currentPage + 1);
    },

    next() {
      this.goTo(this.currentPage + 1);
    },

    prev() {
      this.goTo(this.currentPage - 1);
    },

    first() {
      this.goTo(1)
    },

    last() {

      this.goTo(this.lastPage)
    },

    canGoTo(page) {
      return page <= this.lastPage && page >= 1 && !this.loading;
    },

    goTo(page) {
      if (page > this.lastPage) {
        page = this.lastPage;
      }
      if (page < 1) {
        page = 1;
      }
      this.page = page;
      this.$emit('paginate', page);
    },

    getPageOptions() {
      let pages = [];
      let page = parseInt(this.currentPage);
      if (this.currentPage === 1) {
        pages.push(page);
        pages.push(page + 1);
        if (this.lastPage > 2) {
          pages.push(page + 2);
        }
      } else if (this.currentPage === this.lastPage) {
        if (this.lastPage > 2) {
          pages.push(page - 2);
        }
        pages.push(page - 1);
        pages.push(page);
      } else {
        pages.push(page - 1);
        pages.push(page);
        pages.push(page + 1);
      }
      return pages;
    }
  }

}
</script>
<style>
.page_item_link {
  width: 35px;
  text-align: center;
}
</style>